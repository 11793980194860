<template>
	<div :class="{ 'border-bottom': borderBottom }" class="d-flex align-center" style="height: 36px; background-color: transparent">
		<v-list-item class="pl-6">
			<v-list-item-content>
				<v-row class="d-flex align-center">
					<v-col cols="1">
						<div class="circle mr-0"></div>
					</v-col>
					<v-col cols="10">
						<v-list-item-title v-text="title" :class="{ 'font-weight-700': bold }"></v-list-item-title>
					</v-col>
				</v-row>
			</v-list-item-content>
			<v-list-item-action class="d-flex align-center">
				<div class="d-flex align-center" style="height: 19px">
					<pui-switch
						v-model="opened"
						@input="$emit('click:switch', !!value)"
						color="var(--primary)"
						class="my-auto my-0 py-0"
					></pui-switch>
				</div>
			</v-list-item-action>
		</v-list-item>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true
		},

		title: {
			type: String,
			required: true
		},

		borderBottom: {
			type: Boolean,
			default: false
		},

		borderTop: {
			type: Boolean,
			default: false
		},

		bold: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		opened: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		}
	}
};
</script>

<style scoped>
.border-bottom {
	border-bottom: 2px solid #dadde0 !important;
}

.border-top {
	border-top: 2px solid #dadde0 !important;
}

.font-weight-700 {
	font-weight: 700 !important;
}

* {
	font-size: 0.9rem !important;
	letter-spacing: normal;
	font-weight: normal !important;
}

.circle {
	background: #dadde0;
	border-radius: 50%;
	width: 8px;
	height: 8px;
}
</style>
